import React, { Component } from 'react';
import './App.css';

import { FrontPage } from './pages';

class App extends Component {
  render() {
    return (
      <div className="ba__main">
        <FrontPage />
        {/* Routes and stuff will be here */}
      </div>
    );
  }
}

export default App;
