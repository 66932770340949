import React from 'react';

const FrontPage = () => {
    return (
        <span className="ba__frontpage">
            <h2>ba.fo</h2>
            <span>where slightly thin urls become thinner</span>
            <label>(this'll be a neat little url shortener in the near future)</label>
        </span>
    )
}

export default FrontPage;